import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import ServiceWidget from "./servicewidget.component"

const Services = () => {
  const query = graphql`
    query {
      allContentfulServiceCategory(
        sort: { fields: createdAt }
        filter: { displayInHomePage: { eq: true } }
      ) {
        edges {
          node {
            image {
              fluid {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            id
            name
            slug
            displayInHomePage
          }
        }
      }
    }
  `

  const data = useStaticQuery(query)

  return (
    <section className="pt-5 ptb-xs-40 gray-bg">
      <div className="container">
        <div className="row pb-50 pb-xs-30 text-center">
          <div className="col-md-12">
            <div className="section-title_home">
              <p>What we do?</p>
              <h2>
                Our <span>Service</span>
              </h2>
            </div>
          </div>
        </div>
        <ServiceWidget data={data} />
      </div>
    </section>
  )
}

export default Services
