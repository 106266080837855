import React from "react"
import booklet from "../downloads/royalshades-booklet.pdf"
import profile from "../downloads/royalshades-profile.pdf"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const About = () => {
  const pageData = useStaticQuery(graphql`
    query {
      contentfulNavigationPages(idDontChange: { eq: "home" }) {
        contentImage2 {
          fluid {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        content2{
          json
        }
        content3{
          json
        }
        content4{
          json
        }
      }
    }
  `)

  const data = pageData.contentfulNavigationPages

  return (
    <>
      <div className="col-md-6 col-lg-8">
        <div className="text-content">
          {documentToReactComponents(data?.content2?.json)}
        </div>
        <hr className="divider" />
        <div className="post-content">
          <div className="post-img">
            <Img fluid={data?.contentImage2?.fluid} alt="why-choose-us" />
          </div>
        </div>
        <hr className="divider" />
        <div className="block-title v-line">
          <h2>
            <span>What</span> We Do?
          </h2>
        </div>
        <div className="text-content">
        {documentToReactComponents(data?.content3?.json)}
        </div>
      </div>
      <div className="col-md-6 col-lg-4 mb-30">
        <div className="our-vision padding-40">
          <div className="block-title">
            <h2>
              <span>OUR</span> MISSION
            </h2>
          </div>
          {documentToReactComponents(data?.content4?.json)}
        </div>
        <div className="single-sidebar-widget mt-40">
          <div className="sec-title">
            <h2>
              Download<span> brochure / profile</span>
            </h2>
            <ul className="brochure-btns">
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={booklet}
                  className="clearfix"
                  aria-label="Download Brochure"
                >
                  <i className="fa fa-file-pdf-o" />{" "}
                  <span>Download Brochure</span>{" "}
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={profile}
                  className="clearfix"
                  aria-label="Download Profile"
                >
                  <i className="fa fa-file-pdf-o" />{" "}
                  <span>Download Profile</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default About
