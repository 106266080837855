import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Counter from "../components/counter.component"
import GroupComponent from "../components/group.component"
import Services from "../components/services.component"
import Partners from "../components/partners.component"
import About from "../components/home.about.component"
import Carousel from "../components/carousel.component"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Clients from "../components/clients.component"

const IndexPage = () => {
  const pageData = useStaticQuery(graphql`
    query {
      contentfulNavigationPages(idDontChange: { eq: "home" }) {
        name
        seo {
          title
          description
          keywords
        }
        slug
        hiddenContent {
          childMarkdownRemark {
            html
          }
        }
        bannerImage {
          fluid {
            src
          }
        }
        contentImage {
          fluid {
            src
          }
        }
      }
    }
  `)

  const data = pageData.contentfulNavigationPages

  return (
    <Layout>
      <Helmet>
        <meta
          name="p:domain_verify"
          content="e5b820d67cec0de32b56b3ec978f0927"
        />
      </Helmet>
      <SEO
        title={data.seo.title}
        description={data.seo.description}
        keywords={data.seo.keywords}
      />
      <div>
        <h1 style={{ display: "none" }}>Royal Shades Curtains LLC</h1>
        <Carousel />
        <div id="about-section" className="padding pt-xs-40">
          <div className="container">
            <div className="row pb-50 pb-xs-30 text-center">
              <div className="col-md-12">
                <div className="section-title_home">
                  <p>Who are you?</p>
                  <h2>
                    About <span>Us</span>
                  </h2>
                </div>
              </div>
            </div>
            <div className="row">
              <About />
            </div>
            <div
              className="my-button mb-40"
              style={{ margin: "auto", paddingBottom: "40px" }}
            >
              <Link className="btn-text" to="/about">
                Read more
              </Link>
            </div>
          </div>
        </div>
        {/* <Counter bgImage={data.contentImage.fluid.src} /> */}
        <GroupComponent/>
        <Services />
        <Clients/>
       
        {/* <div
          className="container"
          style={{ display: "none" }}
          dangerouslySetInnerHTML={{
            __html: data.hiddenContent
              ? data.hiddenContent.childMarkdownRemark.html
              : "",
          }}
        ></div> */}
      </div>
    </Layout>
  )
}

export default IndexPage
