import React from "react"
import Slider from "react-slick"
import "./carousel.css"
import { Link, graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import styled, { keyframes } from "styled-components"
import { fadeInRightBig } from "react-animations"

const animation = keyframes`${fadeInRightBig}`
const AnimatedDiv = styled.div`
  animation: 1s ${animation};
`

const RenderHTML = ({ html }) => (
  <span dangerouslySetInnerHTML={{ __html: html }}></span>
)

const NextArrow = ({ className, style, onClick }) => {
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        right: "40px",
        zIndex: "1",
        fontSize: "40px !important",
        top: "40%",
      }}
      onClick={onClick}
      onKeyDown={onClick}
      tabIndex={0}
      role="button"
      aria-label="Next Arrow"
    />
  )
}

const PrevArrow = ({ className, style, onClick }) => {
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        left: "15px",
        zIndex: "1",
        fontSize: "40px !important",
        top: "40%",
      }}
      onClick={onClick}
      onKeyDown={onClick}
      tabIndex={-1}
      role="button"
      aria-label="Prev Arrow"
    />
  )
}

const Carousel = () => {
  const pageQuery = graphql`
    query {
      allContentfulHomeSlider {
        edges {
          node {
            name
            sliderContent {
              childMarkdownRemark {
                html
              }
            }
            image {
              fluid {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
      }
    }
  `

  const data = useStaticQuery(pageQuery)
  const sliderData = data.allContentfulHomeSlider.edges

  const settings = {
    dots: true,
    infinite: true,
    fade: true,
    lazyLoad: false,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  }

  return (
    <div className="main-banner">
      <Slider {...settings}>
        {sliderData.map((slide, i) => (
          <div key={i}>
            <Img fluid={slide.node.image.fluid} alt={slide.node.name} />
            <AnimatedDiv>
              <div className="banner-text text-right">
                <RenderHTML
                  html={slide.node.sliderContent.childMarkdownRemark.html}
                />
                <div>
                  <Link className="main-banner-button" to="/services/">
                    Explore our services
                  </Link>
                </div>
              </div>
            </AnimatedDiv>
          </div>
        ))}
      </Slider>
    </div>
  )
}

export default Carousel
