import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

const ServiceWidget = ({ data }) => {
  return (
    <div id="services-widgets" className="row">
      {data.allContentfulServiceCategory.edges.map(service => (
        <div
          key={service.node.id}
          className="services-block col-lg-3 col-md-4 col-sm-6 col-6 mb-30"
        >
          <div className="inner-box hvr-float">
            <Link to={service.node.slug}>
              <div className="image">
                {service.node.image && (
                  <Img
                    fluid={service.node.image.fluid}
                    alt={service.node.name}
                  />
                )}
              </div>
              <div className="lower-box">
                <h3>{service.node.name}</h3>
              </div>
            </Link>
          </div>
        </div>
      ))}
    </div>
  )
}

export default ServiceWidget
