import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import Slider from "react-slick"
import "./carousel.css"

const NextArrow = ({ className, style, onClick }) => {
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        right: "40px",
        zIndex: "1",
        fontSize: "40px !important",
        top: "40%",
      }}
      onClick={onClick}
      onKeyDown={onClick}
      tabIndex={0}
      role="button"
      aria-label="Next Arrow"
    />
  )
}

const PrevArrow = ({ className, style, onClick }) => {
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        left: "15px",
        zIndex: "1",
        fontSize: "40px !important",
        top: "40%",
      }}
      onClick={onClick}
      onKeyDown={onClick}
      tabIndex={-1}
      role="button"
      aria-label="Prev Arrow"
    />
  )
}

const GroupComponent = () => {
  const settings = {
    dots: true,
    infinite: true,
    fade: true,
    lazyLoad: false,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    slidesPerRow: 4,
    slidesToShow: 4,
    slidesToScroll: 4,
    pauseOnHover: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  }
  const pageData = useStaticQuery(graphql`
    query {
      allContentfulCompanyClients(sort: { fields: createdAt }) {
        edges {
          node {
            clientName
            clientsImage {
              fluid(maxWidth: 200) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)

  const data = pageData.allContentfulCompanyClients.edges
  return (
    <section className="py-5 gray-bg">
      <div className="container">
        <div className="row pb-40 pb-xs-30 text-center">
          <div className="col-md-12">
            <div className="section-title_home">
              <h2>
                Our <span>Clients</span>
              </h2>
            </div>
          </div>
        </div>
        <Slider {...settings}>
          {data.map((slide, i) => (
            <div key={i} className="groups-image">
              <Img
                fluid={slide.node.clientsImage.fluid}
                alt={slide.node.clientName}
              />
            </div>
          ))}
        </Slider>
      </div>
    </section>
  )
}

export default GroupComponent
